<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Thanh toán lái xe</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button @click="openCrud()" class="md-raised md-primary btn-add">Quyết toán<md-tooltip>Quyết toán lái xe (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo lệnh điều xe</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="status">Trạng thái</label>
                                <md-select v-model="search.status" name="status" id="status">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option :value="1">Chưa giải chi</md-option>
                                    <md-option :value="2">Đã giải chi</md-option>
                                    <md-option :value="3">Đã quyết toán</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles">
                                    <label>Tất cả phương tiện</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers">
                                    <label>Tất cả lái xe</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openDriver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số cont</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 275px); overflow-x: auto; width: 100%;">
                        <table id="main-table" class="main-table" style="width: 2750px !important;">
                            <thead>
                                <tr>
                                    <th class="sticky-col" rowspan="3" style="background-color: #DDDDDD; z-index: 999; width: 120px;">HÀNH ĐỘNG</th>
                                    <th class="sticky-col-2" rowspan="3" style="width: 150px; background-color: #E6B9A6; z-index: 999; color: red;">MÃ ĐIỀU ĐỘNG</th>
                                    <th rowspan="3" style="width: 100px; background-color: #E6B9A6; z-index: 998; color: red;">TRẠNG THÁI</th>
                                    <th colspan="9" style="background-color: #FFD0D0; width: 30%;z-index: 998;">THÔNG TIN BILL/BOOKING</th>
                                    <th colspan="3" style="background-color: #BBE9FF; width: 30%;z-index: 998;">ĐỊA ĐIỂM GIAO NHẬN</th>
                                    <th colspan="4" style="background-color: #E0FBE2; width: 40%;z-index: 998;">THÔNG TIN ĐIỀU ĐỘNG</th>
                                    <th colspan="14" style="background-color: #7E8EF1; width: 40%;z-index: 998;">TỔNG HỢP THANH TOÁN</th>
                                    <th v-if="loadding == false && pager.totalPages > 0 && data.length > 0" :colspan="data[0].headers.length * 2" style="background-color: #FFBF78;z-index: 998;">THANH TOÁN LÁI XE</th>
                                </tr>
                                <tr v-if="loadding == false && pager.totalPages > 0">
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Ngày thực hiện</th>
                                    <th rowspan="2" style="width: 150px; background-color: #FFD0D0;z-index: 998;">Khách hàng</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại hình</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">KL (tấn)</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Bill/Booking</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại cont</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số cont</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số seal</th>
                                    <th rowspan="2" style="width: 100px; background-color: #FFD0D0; color: red;z-index: 998;">Closing time</th>
                                    <th rowspan="2" style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi nhận cont</th>
                                    <th rowspan="2" style="width: 100px; background-color: #BBE9FF;z-index: 998;">Kho đóng/rút hàng</th>
                                    <th rowspan="2" style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi trả cont</th>
                                    <th rowspan="2" style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số xe</th>
                                    <th rowspan="2" style="width: 150px; background-color: #E0FBE2;z-index: 998;">Tên lái xe</th>
                                    <th rowspan="2" style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số điện thoại</th>
                                    <th rowspan="2" style="width: 150px; background-color: #E0FBE2;z-index: 998;">Mooc</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Tổng chi phí công ty</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Thanh toán lái xe(Chi phí + Chi hộ)</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Chi phí</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Chi hộ</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">VETC</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Lương lái xe</th>
                                    <th colspan="2" style="width: 150px; background-color: #7E8EF1;z-index: 998;">Phí dịch vụ</th>
                                    <template v-if="loadding == false && pager.totalPages > 0 && data.length > 0" >
                                        <th v-for="(header, inx) in data[0].headers" :key="'est-item-' + inx" colspan="2" style="width: 150px; background-color: #FFBF78; z-index: 998;">
                                            {{ header }}
                                        </th>
                                    </template>
                                </tr>
                                <tr v-if="loadding == false && pager.totalPages > 0" >
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Tổng dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Tổng giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Dự toán</th>
                                    <th style="width: 150px; background-color: #7E8EF1;z-index: 998;">Giải chi</th>
                                    <template v-if="loadding == false && pager.totalPages > 0 && data.length > 0">
                                        <th v-for="(obj, index) in data[0].jobEstimateDtos" :key="'est-value-' + index" style="width: 150px; background-color: #FFBF78;z-index: 998;">
                                            {{ obj.title }}
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data" style="text-align: center;"><span >Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="'job-' + index" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="action sticky-col" style="z-index: 998; display: flex; justify-content: center; align-items: center; width: 120px;">
                                        <md-button  v-if="!item.isInvoice" @click="explain(item)" class="md-fab md-mini md-view">
                                            <i class="uil uil-usd-circle"></i>
                                            <md-tooltip>Giải chi</md-tooltip>
                                        </md-button>
                                        <router-link v-else :to="'/job-invoice/view/' + item.id + '?type=job' ">
                                            <md-button class="md-fab md-mini md-view">
                                                <i class="uil uil-eye"></i>
                                                <md-tooltip>Xem</md-tooltip>
                                            </md-button>
                                        </router-link>
                                        <span v-if="item.isExplain && !item.isInvoice"><md-checkbox v-model="checkedList" :value="item.id"></md-checkbox></span>
                                    </td>
                                    <td class="sticky-col-2" style="z-index: 998;">
                                        <a @click="viewJob(item.id)">{{  item.jobCode }}</a>
                                    </td>
                                    <td>
                                        <md-chip v-if="item.isInvoice" class="status status-green-accent">Đã quyết toán</md-chip>
                                        <md-chip v-else-if="item.isExplain" class="status status-violet">Đã giải chi</md-chip>
                                        <md-chip v-else class="status status-red">Chưa giải chi</md-chip>
                                    </td>
                                    <td>{{ item.jobDate }}</td>
                                    <td>{{ item.customerName }}</td>
                                    <td>{{ getTypeOfTransport(item.typeOfTransport) }}</td>
                                    <td style="text-align: right;">{{ Math.round(item.kg/1000, 2) }}</td>
                                    <td>{{ item.noBooking }}</td>
                                    <td>{{ getGoodsType(item.goodsTypeId) }}</td>
                                    <td>{{ item.serial }}</td>
                                    <td>{{ item.seal }}</td>
                                    <td>{{ item.closingTime }}</td>
                                    <td>
                                        <span v-if="item.typeOfTransport == 1">{{ item.transitDelivery }}</span>
                                        <span v-else-if="item.typeOfTransport == 2">{{ item.transitReceipt }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.typeOfTransport == 1">{{ item.placeOfReceiptGoods }}</span>
                                        <span v-else-if="item.typeOfTransport == 2">{{ item.placeOfDeliveryGoods }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.typeOfTransport == 1">{{ item.placeOfReceiptCont }}</span>
                                        <span v-else-if="item.typeOfTransport == 2">{{ item.placeOfDeliveryCont }}</span>
                                    </td>
                                    <td>{{ item.licensePlate }}</td>
                                    <td>{{ item.driverName }}</td>
                                    <td>{{ item.driverPhone }}</td>
                                    <td>{{ item.mooc }}</td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstCompany) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainCompany > item.totalEstCompany}">{{ formatNumber(item.totalExplainCompany) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstDriver) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainDriver > item.totalEstDriver}">{{ formatNumber(item.totalExplainDriver) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstFee) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainFee > item.totalEstFee}">{{ formatNumber(item.totalExplainFee) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstOnBehalf) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainOnBehalf > item.totalEstOnBehalf}">{{ formatNumber(item.totalExplainOnBehalf) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstVetc) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainVetc > item.totalEstVetc}">{{ formatNumber(item.totalExplainVetc) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstSalary) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainSalary > item.totalEstSalary}">{{ formatNumber(item.totalExplainSalary) }}</span>
                                    </td>
                                    <td style="text-align: right;">{{ formatNumber(item.totalEstService) }}</td>
                                    <td style="text-align: right;">
                                        <span :class="{'price-color-red' : item.totalExplainService > item.totalEstService}">{{ formatNumber(item.totalExplainService) }}</span>
                                    </td>
                                    <td v-for="(obj, index) in item.jobEstimateDtos" :key="'est-value-' + index" style="text-align: right;">
                                        <span :class="{'price-color-red' : obj.isLarge == true}">{{ formatNumber(obj.amount) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <driverList ref="driverList" @close="closeDriver"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <crudForm ref="crudForm" @refresh="refresh" />
        <addForm ref="addForm" @refresh="refresh" />
        <viewForm ref="viewForm" />
    </div>
</template>

<script>
    import jobService from '../../../api/jobService';
    import branchService from '../../../api/branchService';
    import vehicleService from '../../../api/vehicleService';
    import driverService from '../../../api/driverService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import driverList from '../../../components/popup/_DriverList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import crudForm from '../order-vehicle/Crud.vue';
    import addForm from '../job-invoice/Crud.vue';
    import viewForm from '../order-vehicle/View.vue';

    export default {
        components: {
            driverList,
            vehicleList,
            crudForm,
            addForm,
            viewForm
        },
        metaInfo: {
            title: 'Danh sách điều xe'
        },
        data() {
            return {
                loadding: false,
                id: 0,
                customerType: common.companyType.customer,
                typeList: common.typeOfTransport,
                submitted: false,
                search: { pageIndex: 1, pageSize: 25, branchId: 0, driverId: 0, vehicleId: 0, status: 0, keyword: '', fromDate: this.getRangeDate(-7), toDate: common.dateNow, serial: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                orderType: 1,
                vehicleName: '',
                vehicles: [],
                driverName: '',
                drivers: [],
                checkedList: [],
                branchs: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            viewJob(jobId){
                this.$refs.viewForm.open(jobId);
            },

            openCrud(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn lệnh điều xe");
                    return;
                }
                let selectedList = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.data.findIndex(x => x.id == this.checkedList[i]);
                    selectedList.push(this.data[selected]);
                }
                this.$refs.addForm.open(selectedList);
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            explain(obj){
                this.$refs.crudForm.update(obj.id, 'explain');
            },

            refresh(){
                this.checkedList = [];
                this.search.status = 0;
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=0";
                this.$router.push('/job?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&orderType=" + this.orderType;
                this.$router.push('/job?' + url).catch(()=>{});

                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            getData(){
                this.loadding = true;
                jobService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false;  });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.submitDistributeId = response.data.id;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.search.vehicleId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.search.vehicleId = val.id;
                this.vehicleName = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate: searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.search.driverId = item.id;
                this.$refs.driverList.close();
            },

            getDriverSelected(val){
                this.search.driverId = val.id;
                this.driverName = val.fullName;
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name: searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            driverName: function (val) { 
                if(val == ''){
                    this.search.driverId = 0;
                }
            },
            vehicleName: function (val) { 
                if(val == ''){
                    this.search.vehicleId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }
</script>

<style scoped>

    ::-webkit-scrollbar{
        height: 12px;
        width: 0;
        background: #FFF;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #e0e0e0;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        text-align:left;
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    .table-scroll thead tr:nth-child(3) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 91px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

</style>